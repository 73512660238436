import { useEffect, useState } from "react";
import { apiCall } from "../../../../../../crud/api.crud"
import { convertArrayObjectToOptionComponent } from "../../../../../../utils/helper";


// customField/idEntidad/idUser
// const url: string = `customField/226/1407`
const urlPost: string = `customField`
// process.env.REACT_APP_ID_ENTITY sacas el identidad
// zustand usuario hook

const useCustomField = (userId) => {

  const [customFields, setCustomFields] = useState<any>([]);
  const [defaultValue, setDefaultValue] = useState<any>([]);
  const [updateData, setUpdateData] = useState<boolean>(false);
  const [errorUpdate, setErrorUpdate] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  // const [error, setError] = useState<boolean>(false);
  const url: string = `customField/${process.env.REACT_APP_ID_ENTITY}/${userId}`;

  useEffect(() => {
    const fetchData = async () => {

      setIsLoading(true);

      const response = await apiCall(url, null, "GET")

      setCustomFields(response.data
        .map(field => ({
        ...field,
        options: convertArrayObjectToOptionComponent(field.options, "value")
      }
      )));

      const aux = {};
      for (const field of response.data) {
        aux[`${field.id}`] = field.answers?.[0]?.value ?? null;
      }

      setDefaultValue(aux);

      setIsLoading(false);
    }

    fetchData();

  }, [])

  const update = async (payload) => {
    try {
      const response = await apiCall(
        urlPost,
        { ...payload, userId },
        "POST"
      );
  
      if (response.status >= 200 && response.status < 300) {
        setUpdateData(true);
      }
     
    } catch (error) {
      setErrorUpdate(true );
      console.error(error);
    }
  };
  

  return { customFields, update, isLoading, defaultValue, updateData, setUpdateData, setErrorUpdate, errorUpdate }
}

export default useCustomField;
