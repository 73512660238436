import { useTranslation } from 'react-i18next';
import config from '../../../../../config';
import { checkUser } from '../../../../../utils/checkUser';
import Form, { Input, SubmitButton } from '../../../../molecules/Form';
import GooglePlaceAutocomplete from '../../../../molecules/Form/components/GooglePlaceAutocomplete';
import SubmitHomeButton from '../../../../molecules/Form/components/SubmitHomeButton';
import CompanySelect from './Components/CompanySelect';
import GenderSelect from './Components/GenderSelect';
import IdTypeSelect from './Components/IdTypeSelect';
import MaritalSelect from './Components/MaritalSelect';
import NationalitySelect from './Components/NationalitySelect';
import StateOfArSelect from './Components/StateOfArSelect';
import useChageProfile from './hook/useChageProfile';

const getAdress = (addresses) => {
  if (!addresses || !addresses.length) return { address: '', address_info: '' };

  const lastAdress = addresses[addresses.length - 1];

  return { address_id: lastAdress.id, address: lastAdress.name, address_info: lastAdress.info };
};

const columnClasses = 'form-group col-lg-4 col-md-6';

const MyPersonalData = ({ user }) => {
  const { t } = useTranslation();

  const { name, surname, email, id, volunteeringFields = {}, addresses = [] } = user;

  const defaultValue = {
    name,
    surname,
    email,
    id,
    ...volunteeringFields,
    ...getAdress(addresses),
  };

  const { change, isLoading, error, update, birthdateError, setBirthdateError } = useChageProfile(false);

  const handleSubmit = (values) => {
    const today = new Date();
    const birthdate = new Date(values.birthday);

    if (birthdate > today) {
      setBirthdateError(true);
      return;
    }

    update(values);
  };

  return (
    <div className="container mt-4">
      <div className="card shadow bg-white rounded">
        <div className="card-header">{t('profile.profile_msg')}</div>
        <div className="card-block">
          <div className="card-body">
            <Form submit={handleSubmit} defaultValue={defaultValue}>
              <div className="form-row">
                <div className={`form-group ${config.profile_globant ? 'col-lg-12' : 'col-lg-4'} col-md-6`}>
                  <Input
                    label={t(config.profile_globant ? 'profile.name_lastname' : 'profile.name')}
                    name="name"
                    type="text"
                    validations={[
                      { key: 'required', val: true },
                      { key: 'max_length', val: 50 },
                    ]}
                  />
                </div>
                {!config.profile_globant && (
                  <div className={columnClasses}>
                    <Input
                      label={t('profile.surname')}
                      name="surname"
                      type="text"
                      validations={[
                        { key: 'required', val: true },
                        { key: 'max_length', val: 50 },
                      ]}
                    />
                  </div>
                )}
                <div className={columnClasses}>
                  <Input
                    label={t('profile.email')}
                    name="email"
                    type="email"
                    validations={[
                      { key: 'required', val: true },
                      { key: 'email', val: true },
                    ]}
                  />
                </div>
                {!config.profile_globant && (
                  <div className={columnClasses}>
                    <GenderSelect />
                  </div>
                )}
                {!config.exception && !config.profile_globant && (
                  <div className={columnClasses}>
                    <MaritalSelect />
                  </div>
                )}
                {config.companyTypes && (
                  <div className={columnClasses}>
                    <CompanySelect />
                  </div>
                )}
                {!config.profile_globant && (
                  <div className={columnClasses}>
                    <Input label={t('profile.profile_msg8')} name="birthday" type="date" validations={[]} />
                  </div>
                )}
                {!config.profile_globant && (
                  <>
                    <div className={columnClasses}>
                      <IdTypeSelect />
                    </div>
                    <div className={columnClasses}>
                      <Input
                        label={t('profile.profile_msg7')}
                        name="id_number"
                        type="text"
                        validations={[
                          { key: 'required', val: true },
                          { key: 'max_length', val: 50 },
                        ]}
                      />
                    </div>
                  </>
                )}
                {!config.profile_globant && (
                  <>
                    <div className={columnClasses}>
                      <NationalitySelect />
                    </div>
                    <div className={columnClasses}>
                      <Input
                        label={t('profile.profile_msg10')}
                        name="phone_number"
                        type="number"
                        validations={[{ key: 'max_length', val: 25 }]}
                      />
                    </div>
                    <div className={columnClasses}>
                      <Input
                        label={t('profile.profile_msg11')}
                        name="mobile_number"
                        type="number"
                        validations={[{ key: 'max_length', val: 25 }]}
                      />
                    </div>
                  </>
                )}

                {!config.exception && !config.profile_globant && (
                  <div className={columnClasses}>
                    <Input label={t('profile.profile_msg12')} name="linkedin_url" type="text" validations={[]} />
                  </div>
                )}
                {config.catalogTalents && (
                  <>
                    <div className={columnClasses}>
                      <Input label={t('profile.portfolio')} name="portfolio_url" type="text" validations={[]} />
                    </div>
                    <div className={columnClasses}>
                      <Input label={t('profile.curriculum')} name="curriculum_url" type="text" validations={[]} />
                    </div>
                  </>
                )}
              </div>
              {!config.withState && !config.profile_globant && (
                <div className="form-row">
                  <div className="form-group col-md-6">
                    <GooglePlaceAutocomplete
                      label={t('profile.profile_msg13')}
                      name="address"
                      validations={[{ key: 'required', val: true }]}
                    />
                  </div>
                  <div className="form-group col-md-6">
                    <Input label={t('profile.profile_msg14')} name="address_info" type="text" validations={[]} />
                  </div>
                </div>
              )}
              {!config.exception && config.withState && (
                <div className="form-row">
                  <div className="form-group col-lg-8">
                    <StateOfArSelect />
                  </div>
                  <div className="form-group col-lg-4">
                    <Input label={t('profile.profile_msg14')} name="address_info" type="text" validations={[]} />
                  </div>
                </div>
              )}

              <SubmitButton isloading={isLoading} />
              <SubmitHomeButton isloading={isLoading} canRedirect={change} />
            </Form>
          </div>
        </div>
      </div>
      {error && (
        <div className="alert alert-danger" role="alert">
          {t('profile.extra_msg1')}
        </div>
      )}
      {birthdateError && (
        <div className="alert alert-danger" role="alert">
          {t('profile.birthdate_error')}
        </div>
      )}
      {change && (
        <div className="alert alert-success" role="alert">
          {t('profile.extra_msg2')}
        </div>
      )}
      {!change && !checkUser(user).length && (
        <div className="alert alert-warning" role="alert">
          {t('profile.extra_msg3')}
        </div>
      )}
    </div>
  );
};

export default MyPersonalData;
