import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import config from '../../../config';
import { LuCalendarCheck } from "react-icons/lu";
import { FiUsers } from "react-icons/fi";
import { RiErrorWarningLine } from "react-icons/ri";

const getColors = (color) => {
  switch (color) {
    case 'success':
      return { color: '#fff', backgroundColor: '#34bfa3' };
    case 'secondary':
      return { color: '#212529', backgroundColor: '#ebedf2' };
    case 'brand':
      return { color: '#fff', backgroundColor: '#716aca' };
    case 'info':
      return { color: '#fff', backgroundColor: '#36a3f7' };
    case 'primary':
      return { color: '#fff', backgroundColor: '#5867dd' };
    case 'warning':
      return { color: '#fff', backgroundColor: '#ffb822' };
    case 'accent':
      return { color: '#fff', backgroundColor: '#00c5dc' };
    case 'metal':
      return { color: '#fff', backgroundColor: '#c4c5d6' };
    case 'danger':
      return { color: '#fff', backgroundColor: '#f4516c' };
    default:
      return { color: '#fff', backgroundColor: '#34bfa3' };
  }
};

const Span = styled.span`
  padding: 5px 15px;
  background-color: ${(props) => getColors(props.color).backgroundColor};
  color: ${(props) => getColors(props.color).color};
  display: block;
  border-radius: 15px;
  text-align: center;
  margin-bottom: 5px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Detail = ({ activity, showLocation = false }) => {

  const addHours = (newDate) =>{
    const date = new Date(newDate);
    const adjustedDate = new Date(date.getTime() + date.getTimezoneOffset() * 60000);
    const formattedDate = adjustedDate.toLocaleDateString('es-ES');
    return formattedDate;
  }

  const { t } = useTranslation();

  const getStatusTraduction = (activity) => {
    switch (activity.status_alias) {
      case 'Terminada':
        return t('activities.status_msg9');
      case 'Abierta':
        return t('activities.status_msg6');
      case 'En borrador':
        return t('activities.status_msg10');
      case 'En curso':
        return t('activities.status_msg8');
      case 'Cerrada':
        return t('activities.status_msg7');
      default:
        return activity.status_alias;
    }
  };

  const getLocation = () => {
    if (activity.locations && activity.locations.length) {
      if (showLocation) {
        return activity.locations.map((loc) => <div key={`adressKey-${loc.id}`}>{loc.address}</div>);
      }
      return activity.locations && activity.locations.length === 1 ? activity.locations[0].address : 'Multiple';
    }
    return 'Sin asignar';
  };

  return (
    <Container>
      {!config.profile_globant && (<Span color={activity.color_alternative_type}>{activity.alternative_type}</Span>)}
      {!config.profile_globant && (<div>
        <span className="strong">{t('home.card_msg')}:</span>
        {getStatusTraduction(activity)}
      </div>)}
      {activity.estimated_hours && !config.profile_globant ? (
        <div>
          <span className="strong">{t('home.card_msg6')}:</span>
          {`${activity.estimated_hours} ${t('hours.hours_msg4')}`}
        </div>
      ) : null}
      {activity.continuous_activity ? (
        <div>
          {config.profile_globant && (<LuCalendarCheck />)}
          <span style={config.profile_globant ? {fontSize:'13px', paddingLeft: '3px'} : null} className={config.profile_globant ? '' : "strong text-info"}>{t('home.card_msg7')}</span>
        </div>
      ) : (
        <div>
          {!config.profile_globant ? (
            <div>
              <div>
                <span className="strong">{t('home.card_msg1')}:</span>
                {activity.activity_from === activity.activity_to
                  ? activity.activity_from
                  : `${activity.activity_from} al ${activity.activity_to}`}
              </div>
              <div>
                <span className="strong">{t('home.card_msg2')}:</span>
                {activity.deadline.slice(0, 10)}
              </div>
            </div>
          ):(
            <div>
              <div>
                <LuCalendarCheck />
                <span style={{fontSize:'13px', paddingLeft: '3px'}} className="strong">{t('home.card_msg1')}:</span>
                <span style={{fontSize:'13px', color: '#5B5B5B'}}>{activity.activity_from === activity.activity_to
                  ? addHours(activity.activity_from)
                  : `del ${addHours(activity.activity_from)} al ${addHours(activity.activity_to)}`}
                </span>
              </div>
              <div>
                <RiErrorWarningLine />
                <span style={{fontSize:'13px', paddingLeft: '3px'}} className="strong">{t('home.card_msg2')}:</span>
                <span style={{fontSize:'13px', color: '#5B5B5B'}}>{addHours(activity.deadline.slice(0, 10))}</span>
              </div>
            </div>
          )}
        </div>
      )}
      {!!activity.quota && (
        <div>
          {!config.profile_globant ? (
            <div>
            <span className="strong">{t('home.card_msg3')}:</span>
            {activity.quota}
            </div>
          ) : (
            <div>
            <FiUsers />
            <span style={{fontSize:'13px', paddingLeft: '3px'}} className="strong">{t('home.card_msg3')}:</span>
            <span style={{fontSize:'13px', color: '#5B5B5B'}}>{activity.quota}</span>
            </div>
          )}
        </div>
      )}
      {config.activities?.card?.weAreIn && !config.profile_globant && (
        <div>
          <span className="strong">{t('home.card_msg4')}:</span>
          {getLocation()}
        </div>
      )}
    </Container>
  );
};
export default Detail;
