import { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { FaEdit } from 'react-icons/fa';
import styled from 'styled-components';
import Loading from '../../../molecules/Loading';
import Table from './components/Table';
import Pagination from '../../../molecules/Pagination';
import Button from '../../../molecules/Button';
import EditModal from './components/EditModal';
import HoursReactour from '../../../main/components/Reactour/HoursReactour';
import useActivietiesUserHours from './hooks/useActivietiesUserHours';
import { useTranslation } from 'react-i18next';
import config from '../../../../config';

const ButtonAddHours = styled(Button)`
  padding: 10px;
  margin-right: 10px;
  display: flex;
  align-items: center;
  background: #3f86f6;
`;

const ButtonDisable = styled(Button)`
  padding: 10px;
  margin-right: 10px;
  display: flex;
`;

const Hours = ({ isOpenTour }) => {
  const { t } = useTranslation();


  const { activities, loading, meta, update } = useActivietiesUserHours();

  const [editModal, setEditModal] = useState(null);

  const refreshNow = () => {
    update();
    setEditModal(null);
  };

  const openEditModal = (row) => {
    setEditModal(row);
  };

  const columns = useMemo(
    () => [
      {
        name: t('activities.activities_msg11'),
        selector: 'actividad',
        sortable: true,
        cell: function cell(row) {
          return <Link to={row.url}>{row.actividad}</Link>;
        },
      },
      {
        name: t('hours.hours_msg2'),
        selector: 'createdAt',
        sortable: true,
      },
      {
        name: t('hours.hours_msg3'),
        selector: 'activityDay',
        sortable: true,
      },
      {
        name: t('hours.hours_msg4'),
        selector: 'horas',
        sortable: true,
      },
      {
        name: t('hours.hours_msg5'),
        selector: 'editar',
        sortable: false,
        omit: config.estimated_hours,
        cell: function cell(row) {
          return row.estado ? (
            <ButtonAddHours onClick={() => openEditModal(row)}>
              <FaEdit />
            </ButtonAddHours>
          ) : (
            <ButtonDisable>
              <FaEdit />
            </ButtonDisable>
          );
        },
      },
      {
        name: t('hours.hours_msg6'),
        selector: 'estado',
        sortable: true,
      },
    ],
    [],
  );

  if (loading || !activities) {
    return <Loading />;
  }

  if (activities) {
    const data = activities.map((u) => ({
      id: u.id,
      createdAt: u.created_at.slice(0, 10),
      activityDay: u.activity_day.slice(0, 10),
      actividad: u.activity ? u.activity.title : t('hours.hours_msg9'),
      horas: u.hours,
      estado: !u.validated_to,
      url: `/detail/${u.activity_id}`,
      commentary: u.commentary,
    }));

    return (
      <>
        {isOpenTour && <HoursReactour />}
        <EditModal data={editModal} refresh={refreshNow} />
        <div className="container mt-4">
          <div className="card shadow  bg-white rounded">
            <div className="card-block">
              <div className="card-body">
                <Table data={data} columns={columns} title={t('hours.hours_msg')} />
                <Pagination meta={meta} action={update} className="d-flex justify-content-end mt-2" withRedux={false} />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }

  return null;
};

export default Hours;
