import { Box, Button, SimpleGrid, Stack, Text } from '@chakra-ui/react';
import { Guide, Lesson, Quiz } from 'interfaces/guide.interface';
import { DownloadCertificate } from 'modules/classroom/components/DownloadCertificate';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';
import { useMemo } from 'react';

interface Props {
  guide: Guide;
}

export function Progress({ guide }: Props) {
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const { t } = useTranslation('translation', { keyPrefix: 'classroom' });
  const lessons = useMemo(() => {
    return guide.topics?.flatMap((t) => t.chapters).flatMap((c) => c.lessons) || [];
  }, [guide.topics]);
    

  const quizzes = useMemo(() => {
    return lessons.reduce((acc, curr) => (curr?.quiz ? [...acc, curr.quiz] : acc), [] as Quiz[]) || [];
  }, [lessons]);

  const quizzesApproved = useMemo(() => {
    return quizzes?.filter((q) => q.answer);
  }, [quizzes]);

  const classesViewed = useMemo(() => {
    return lessons.reduce(
      (acc, curr) => (curr.resources.some((r) => r.completed_at) ? [...acc, curr] : acc),
      [] as Lesson[],
    );
  }, [lessons]);

  return (
    <SimpleGrid columns={{ sm: 1, lg: 3 }} gap={5}>
      <Box bg="gray.100" color="black" boxShadow="base" textAlign="start" p={2}>
        <Text>{t('chapters.complete')}</Text>
        <Text fontWeight="bold" fontSize={{ base: '2xl', md: '4xl' }}>
          {Math.round((classesViewed.length / lessons.length) * 100)}%
        </Text>
        <Text>
          {classesViewed.length} {t('chapters.completed')}
        </Text>
      </Box>
     {quizzes?.length > 0 && <Box bg="gray.100" color="black" boxShadow="base" textAlign="start" p={2}>
        <Text>{t('evaluation_approved')}</Text>
        <Text fontWeight="bold" fontSize="3xl">
          {Math.round((quizzesApproved?.length / quizzes?.length) * 100)}%
        </Text>
        <Text>
          {quizzesApproved?.length} {t('evaluation_approved')}
        </Text>
      </Box>}
      <Stack flexDir={{ base: 'column', lg: 'column' }} justify="space-between">
        <Button
          size="lg"
          fontSize="sm"
          isDisabled={!classesViewed.length}
          onClick={() => {
            const lastLesson = classesViewed[classesViewed.length - 1];

            if (lastLesson) {
              history.push(`/detail/${id}/guide/lesson/${lastLesson.id}`);
            }
          }}
        >
          {t('last_lesson')}
        </Button>

        {guide.hasCertificate && (
          <DownloadCertificate size="lg" fontSize="sm" isDisabled={guide.status !== 7}>
            {t('download_certificate')}
          </DownloadCertificate>
        )}
      </Stack>
    </SimpleGrid>
  );
}
