import {
  Box,
  Flex,
  Heading,
  IconButton,
  Image,
  Input,
  InputGroup,
  InputRightElement,
  Stack,
  Text,
} from '@chakra-ui/react';
import AnonymousUser from 'assets/anonymous_user.jpg';
import { apiCall } from 'crud/api.crud';
import { Comment } from 'interfaces';
import { FormEvent, useState } from 'react';
import { FiSend } from 'react-icons/fi';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import useStore from 'store/zustand/store';
import { STORAGE_URL } from 'utils/talents.util';

export function Comments(props: { comments: Comment[] }) {
  const { id } = useParams<{ id: string }>();
  const { user } = useStore((state) => state);
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState('');
  const [comments, setComments] = useState<Comment[]>(props.comments ?? []);

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    if (!user) return;
    setLoading(true);
    try {
      await apiCall(`entity/${process.env.REACT_APP_ID_ENTITY}/talents/${id}/comment`, { content: value }, 'POST');
      setComments((prev) => [
        ...prev,
        {
          id: comments.length,
          content: value,
          user_id: user.id,
          user,
        },
      ]);
      setValue('');
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Stack spacing={4}>
      {!comments.length && (
        <Stack minH="150px" align="center" justify="center" color="gray">
          <Text>Sin comentarios</Text>
        </Stack>
      )}
      {!!comments.length && (
        <Stack minH="150px" maxH="50vh" overflowY="auto">
          {comments.map(({ id, user, content }) => (
            <Flex bg="white" rounded="xl" px={4} py={2} gap={2} key={id}>
              <Link to={`/profile/${user.id}`} target="_blank">
                <Image
                  src={user.talent.image ? `${STORAGE_URL}/${user.talent.image}` : AnonymousUser}
                  borderRadius="full"
                  boxSize="40px"
                />
              </Link>
              <div>
                <Link to={`/profile/${user.id}`} className="text-dark" target="_blank">
                  <Heading size="sm" fontWeight="medium" m={0}>
                    {user.name} {user.surname}
                  </Heading>
                </Link>
                <Text fontWeight="light">{content}</Text>
              </div>
            </Flex>
          ))}
        </Stack>
      )}
      <Box as="form" onSubmit={handleSubmit} bg="white" rounded="xl" px={4} py={2}>
        <InputGroup size="md">
          <Input
            pr="3.5rem"
            placeholder="Enter message"
            value={value}
            onChange={(e) => setValue(e.target.value)}
            isRequired
          />
          <InputRightElement width="3.5rem">
            <IconButton type="submit" size="sm" icon={<FiSend size={18} />} aria-label="Send comment" isLoading={loading} />
          </InputRightElement>
        </InputGroup>
      </Box>
    </Stack>
  );
}
