import { useTranslation } from 'react-i18next';
import config from '../../../../../config';
import Form, { Input, SubmitButton } from '../../../../molecules/Form';
import Select from '../../../../molecules/Form/components/Select';
import useCustomField from './hook/useCustomField';
import { useEffect, useState } from 'react';

const ExtraPersonalData = ({ userId }) => {
  const [showMessage, setShowMessage] =useState(false)
  const { t } = useTranslation();

  const { customFields, isLoading, defaultValue, update, updateData, setUpdateData, setErrorUpdate, errorUpdate } = useCustomField(userId);
  useEffect(() => {
    if (updateData) {
      setShowMessage(true);
      const successTimer = setTimeout(() => {
        setShowMessage(false);
        setUpdateData(false);
      }, 2000);

      return () => clearTimeout(successTimer);
    }

    if (errorUpdate) {
      setShowMessage(true);
      const errorTimer = setTimeout(() => {
        setShowMessage(false);
        setErrorUpdate(false); 
      }, 2000);

      return () => clearTimeout(errorTimer);
    }
  }, [updateData, setUpdateData, errorUpdate, setErrorUpdate]);

  
  if (!customFields) return null;
  return (
    <div className="container mt-4">
      {customFields.length > 0 && <div className="card shadow bg-white rounded">
        {!config.profile_globant ? (
          <div className="card-header">{t('profile.extra_msg')}</div>
        ) : (
          <div className="card-header">{t('profile.extra_msg_globant')}</div>
        )}
       <div className="card-block">
          <div className="card-body">
            {!isLoading && (
              <Form submit={update} defaultValue={defaultValue}>
                <div className="form-row">
                  {!isLoading &&
                    customFields.map((field) => (
                      <div key={`fieldCustom-${field.id}`} className="form-group col-md-6">
                        {field.type === 'select' ? (
                          <Select
                            label={field.title}
                            name={`${field.id}`}
                            options={field.options}
                            validations={[{ key: 'required', val: true }]}
                          />
                        ) : (
                          <Input
                            label={field.title}
                            name={`${field.id}`}
                            type={field.type}
                            validations={[{ key: 'required', val: true }]}
                          />
                        )}
                      </div>
                    ))}
                </div>
               { showMessage && updateData &&   <div className="alert alert-success" role="alert">          
                {t('profile.extra_msg2')}
               </div>}
                {showMessage && errorUpdate && 
                <div className="alert alert-warning" role="alert">
                  {t('profile.extra_msg1')}
              </div>}
              <SubmitButton isloading={isLoading} />
              </Form>
            )}
          </div>
        </div>
      </div>}
    </div>
  );
};

export default ExtraPersonalData;
