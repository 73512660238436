import { applyMiddleware, compose, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';

import { rootReducer, rootSaga } from './rootDuck';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;



const sagaMiddleware = createSagaMiddleware();
const store = createStore(
  rootReducer(),
  composeEnhancers(applyMiddleware( sagaMiddleware)),
);

sagaMiddleware.run(rootSaga);

export default store;
