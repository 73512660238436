import { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router';
import { useHistory, Link as RouterLink } from 'react-router-dom';
import styled from 'styled-components';
import { apiCall } from '../../../../crud/api.crud';

import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import useGetUserId from '../../../../hooks/api/useGetUserId';
import { Activity, Survey } from '../../../../interfaces/activity';
import { ButtonSecondary, ButtonTernary } from '../../../molecules/Button';
import Detail from '../../../molecules/Detail';
import Loading from '../../../molecules/Loading';
import OnlyOds from '../../../molecules/Ods/OnlyOds';
import ActivityButton from '../../../shared/ActivityButton';
import Tabs from './components/Tabs';
import { actions as modalAction } from '../../../../store/ducks/modal.duck';
import { BsChatLeft } from 'react-icons/bs';
import { BiArrowBack } from 'react-icons/bi';
import { IoChevronBack } from 'react-icons/io5';
import { useSocketContext } from 'context/SocketContext';
import config from '../../../../config';

const isArcos = +process.env.REACT_APP_ID_ENTITY! === 8399;

const Img = styled.img`
  width: 100%;
  border-radius: 15px;
  margin-bottom: 40px;
`;

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 25px;

  @media (min-width: 768px) {
    grid-template-columns: 40% 1fr;
  }
  ${config.profile_globant && 'margin-bottom: 45px;'}
`;

const LeftContainer = styled.div`
  display: inline-block;
  width: 100%;
  .strong {
    font-weight: bold;
    margin: 0 5px 5px 0;
  }
  ${config.profile_globant && 'overflow: auto;'}
  ${config.profile_globant && 'background-color: white;'}
  ${config.profile_globant && 'padding: 10px 10px 20px 10px;'}
  ${config.profile_globant && 'border: 2px solid #e7e7e7;'}
  ${config.profile_globant && 'border-radius: 15px;'}
`;
const RigthContainer = styled.div`
  hr {
    border: 0.5px solid #c4c4c4;
    border-radius: 5px;
  }
`;

const ButtonDonation = styled.a`
  background: #ff4450;
  &:focus {
    border: none;
    outline: none;
  }

  display: inline-block;
  padding: 10px 30px;
  text-align: center;
  color: #fffdfd;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  //box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.14), 0px 3px 14px rgba(0, 0, 0, 0.12), 0px 5px 5px rgba(0, 0, 0, 0.2);
  border-radius: 25px;
  border: none;
  transition: all 0.3s ease 0s;
  cursor: pointer;
  outline: none;

  &:hover {
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    transform: translateY(-3px);
    text-decoration: none;
    color: #fffdfd;
  }
  &:active {
    box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
    transform: translateY(3px);
  }
  &:focus {
    outline: none;
  }
`;

interface TParams {
  id: string;
}

export const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const DetailActivity = () => {
  const { t } = useTranslation();
  const { id } = useParams<TParams>();
  const dispatch = useDispatch();
  const [activity, setActivity] = useState<Activity>();
  const history = useHistory();
  const query = useQuery();
  const { rooms } = useSocketContext();
  const goBack = () => {
    history.goBack();
  };
  const userId = useGetUserId();
  const roomName = `activity-${id}`;
  const room = rooms.find((room) => room.name === roomName);

  useEffect(() => {
    apiCall(
      `activity/showwithuser/${id}?include=survey.fields.options,locations,form.fields.options${
        userId ? '&userId=' + userId : ''
      }`,
      null,
      'GET',
    ).then((response) => {
      const activity = response.data.data[0];
      setActivity(activity);
      if (query.get('comment_activity')) {
        commentaryModal(activity.id, activity.survey, activity.estimated_hours);
      }
    });
  }, []);
  const commentaryModal = (activity_id: number, survey: Survey | null, estimated_hours: number): void => {
    dispatch(
      modalAction.modalShow({
        modalProps: {
          open: true,
          activity_id,
          url: 'activity/addCommentary',
          survey,
          estimated_hours,
        },
        modalType: 'commentary',
      }),
    );
  };

  if (!activity) return <Loading />;
  const handleClick = () => {
    apiCall(
      `interestedLink`,
      {
        activity_id: activity.id,
        user_id: userId,
      },
      'POST',
    ).finally(() => {
      window.open(activity.url_donar, '_blank');
    });
  };
  return (
    <div className="container mt-4">
      <div className="d-flex w-100 my-4 justify-content-end align-items-end">
        {config.profile_globant ? (
          <ButtonTernary onClick={goBack} className="mr-auto">
            <IoChevronBack />
            Volver
          </ButtonTernary>
        ) : (
          <ButtonSecondary onClick={goBack} className="mr-auto">
            <BiArrowBack />
          </ButtonSecondary>
        )}
        {room && (
          <ButtonSecondary style={{ color: 'white ' }} as={RouterLink} to={`/chat/${activity.id}`} className="mx-2">
            <BsChatLeft />
          </ButtonSecondary>
        )}

        {!isArcos && !config.profile_globant && <ActivityButton activity={activity} />}
      </div>

      <Container>
        <div>
          <LeftContainer>
            {activity.description_image && (
              <Img
                className="card-img-top"
                alt="img de actividad"
                src={`https://app.fonselp.com/storage/${activity.description_image}`}
              />
            )}
            <Detail activity={activity} showLocation />
            <hr />
            <span style={{ margin: '6px' }}></span>
            {activity.url_donar && (
              <ButtonDonation onClick={handleClick}>{activity.url_donar_name || t('home.card_msg5')}</ButtonDonation>
            )}
            <div>
              <span className="strong">{t('home.filter_msg3')}:</span>
              <OnlyOds id={activity.sdg_id} />
            </div>
          </LeftContainer>
        </div>
        <RigthContainer>
          <Tabs activity={activity} />
        </RigthContainer>
      </Container>
      <div
        style={config.profile_globant ? { height: '75px' } : {}}
        className={`d-flex w-100 justify-content-end ${
          config.profile_globant ? 'align-items-center fixed-bottom bg-light border-top' : 'my-4 align-items-end'
        }`}
      >
        <div
          className={`${
            config.profile_globant &&
            'container d-flex align-items-center justify-content-md-end justify-content-center'
          }`}
        >
          {!config.profile_globant && (
            <ButtonSecondary onClick={goBack} className="mr-2">
              {t('button.button_msg')}
            </ButtonSecondary>
          )}
          {!isArcos && <ActivityButton activity={activity} />}
        </div>
      </div>
    </div>
  );
};

export default DetailActivity;
