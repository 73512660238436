import { clearStorage } from "../../../crud/api.crud"

const createLogout = (set) =>({
  logout: () =>{
    clearStorage(['i18nextLng'])
    set({user:null})
  }
})

export default createLogout;
