import { useTranslation } from "react-i18next";

const Checkbox = ({checked, setChecked}) => {
    const { t } = useTranslation();

    const change = ()=>{
        setChecked(!checked)
    }

    return (
             <label style={{fontSize:".8rem", fontWeight:"600"}}>
                <input type="checkbox" checked={checked} onChange={change}/> {t('tour.checkbox_msg')}
            </label>
    );
}

export default Checkbox;
