import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import styled from 'styled-components';
import { apiCall } from '../../../../../crud/api.crud';
import config from '../../../../../config';

const animatedComponents = makeAnimated();

const Card = styled.div`
  background-color: #ffffff;
  border: 2px solid #e7e7e7;
  border-radius: 15px;
  margin-bottom: 10px;

  hr {
    border: 2px solid #c4c4c4;
    border-radius: 5px;
  }

  .strong {
    font-weight: bold;
    margin: 0 5px 5px 0;
  }
  h3 {
    font-size: 20px;
  }
`;
const Container = styled.div`
  padding: 25px;
`;

const DivMargin = styled.div`
  margin: 25px 0;
`;

const convertResponseToOptions = (array, label, value) => {
  if (!value) value = label;
  if (array.length == 0) return [];
  return array.map((v) => ({ value: v[value], label: v[label] }));
};

const FilterColumn = ({ setFilter, filter }) => {
  const { t } = useTranslation();

  const addFilter = (name) => (value) => {
    setFilter({ ...filter, [name]: value });
  };

  const [filtersCustom, setFiltersCustom] = useState({});

  const addCustomFilter = (name) => (value) => {
    setFiltersCustom({ ...filtersCustom, [name]: value });
  };

  useEffect(() => {
    let aux = [];
    for (const filterCustom in filtersCustom) {
      if (filtersCustom[filterCustom]) {
        aux = [...aux, ...filtersCustom[filterCustom]];
      }
    }
    if (aux.length > 0) {
      const customFilter = addFilter('inCustomFilter');
      customFilter(aux);
    }
  }, [filtersCustom]);

  const [response, setResponse] = useState('');

  useEffect(() => {
    apiCall(`filters/${process.env.REACT_APP_ID_ENTITY}`, null, 'GET').then((res) => setResponse(res.data.data));
  }, []);

  const setName = (event) => {
    addFilter('withTitle')(event.target.value);
  };
  return (
    <Card className={config.profile_globant ? "globant__card__container__columns h-75 d-none d-xl-block" : "card__container__columns h-75 d-none d-xl-block"}>
      <Container>
        <h3>{t('home.home_msg2')}:</h3>

        <DivMargin>
          <input
            className="form-control"
            onChange={setName}
            placeholder={t('home.filter_msg')}
            value={filter ? filter.withTitle : ''}
          />
        </DivMargin>

        {response.alternativeType && (
          <DivMargin>
            <Select
              placeholder={t('home.filter_msg2')}
              components={animatedComponents}
              isMulti
              onChange={addFilter('withAlternativeType')}
              options={convertResponseToOptions(response.alternativeType, 'alternative_type')}
              defaultValue={filter ? filter.withAlternativeType : []}
            />
          </DivMargin>
        )}

        {response.ods && (
          <DivMargin>
            <Select
              placeholder={t('home.filter_msg3')}
              components={animatedComponents}
              isMulti
              onChange={addFilter('inSdgs')}
              options={convertResponseToOptions(response.ods, 'label', 'id')}
              defaultValue={filter ? filter.inSdgs : []}
            />
          </DivMargin>
        )}

        {response.entity_type && (
          <DivMargin>
            <Select
              placeholder={t('home.filter_msg4')}
              components={animatedComponents}
              isMulti
              onChange={addFilter('inEntityType')}
              options={convertResponseToOptions(response.entity_type, 'name', 'id')}
              defaultValue={filter ? filter.inEntityType : []}
            />
          </DivMargin>
        )}
        {response.filtersCustom &&
          response.filtersCustom.map((filter) => (
            <DivMargin key={`filter${filter.id}`}>
              <Select
                placeholder={filter.title}
                components={animatedComponents}
                isMulti
                onChange={addFilter('inCustomFilter' + filter.order)}
                options={convertResponseToOptions(filter.values, 'value', 'id')}
                defaultValue={filter ? filter['inCustomFilter' + filter.order] : []}
              />
            </DivMargin>
          ))}
      </Container>
    </Card>
  );
};

export default FilterColumn;
