import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from '../../../../../store/ducks/auth.duck';
// import list from '../../../../molecules/Ods/ods/infoOds';

const Ods = ({ ods, userId }) => {
  const { t } = useTranslation();

  const { loading, error } = useSelector((state) => ({
    loading: state.auth.loading,
    error: state.auth.error,
  }));
  const dispatch = useDispatch();
  const listIds = ods.map((od) => od.id);

  const [odsForm, setOdsForm] = useState(listIds);

  const changeHandler = (e) => {
    const value = Number(e.target.value);
    if (odsForm.includes(value)) {
      setOdsForm(odsForm.filter((od) => od != value));
    } else {
      setOdsForm([...odsForm, value]);
    }
  };
  const submit = () => {
    dispatch(actions.chageOds({ categoriesIds: odsForm, id: userId }));
  };
  const list = {
  name: [
    t('profile.ods_msg1'),
    t('profile.ods_msg2'),
    t('profile.ods_msg3'),
    t('profile.ods_msg4'),
    t('profile.ods_msg5'),
    t('profile.ods_msg6'),
    t('profile.ods_msg7'),
    t('profile.ods_msg8'),
    t('profile.ods_msg9'),
    t('profile.ods_msg10'),
    t('profile.ods_msg11'),
    t('profile.ods_msg12'),
    t('profile.ods_msg13'),
    t('profile.ods_msg14'),
    t('profile.ods_msg15'),
    t('profile.ods_msg16'),
    t('profile.ods_msg17'),
  ],
  link:[
    'https://www.un.org/sustainabledevelopment/es/poverty/',
    'https://www.un.org/sustainabledevelopment/es/hunger/',
    'https://www.un.org/sustainabledevelopment/es/health/',
    'https://www.un.org/sustainabledevelopment/es/education/',
    'https://www.un.org/sustainabledevelopment/es/gender-equality/',
    'https://www.un.org/sustainabledevelopment/es/water-and-sanitation/',
    'https://www.un.org/sustainabledevelopment/es/energy/',
    'https://www.un.org/sustainabledevelopment/es/economic-growth/',
    'https://www.un.org/sustainabledevelopment/es/infrastructure/',
    'https://www.un.org/sustainabledevelopment/es/inequality/',
    'https://www.un.org/sustainabledevelopment/es/cities/',
    'https://www.un.org/sustainabledevelopment/es/sustainable-consumption-production/',
    'https://www.un.org/sustainabledevelopment/es/climate-change-2/',
    'https://www.un.org/sustainabledevelopment/es/oceans/',
    'https://www.un.org/sustainabledevelopment/es/biodiversity/',
    'https://www.un.org/sustainabledevelopment/es/peace-justice/',
    'https://www.un.org/sustainabledevelopment/es/globalpartnerships/',
]
}

  console.log(error)
  return (
    <div className="container mt-4">
      <div className="card shadow  bg-white rounded">
        <div className="card-header">{t("home.filter_msg3")}</div>
        <div className="card-block">
          <div className="card-body">
            <div className="form-group  m-form__group">
              <div className="row ml-4">
                {list.name.map((ods, index) => (
                  <div className="col-lg-4 mb-4" key={`odsId-${index}`}>
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id={`inlineCheckbox${index + 1}`}
                      value={index + 1}
                      checked={odsForm.includes(index + 1)}
                      onClick={changeHandler}
                    />
                    <label
                      className="form-check-label"
                      htmlFor={`inlineCheckbox${index + 1}`}
                    >
                      <a href={list.link[index]} target="_blank" rel="noreferrer">{ods}</a>
                    </label>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>

        <div className="card-footer">
          {loading ? (
            <button className="button" type="submit">
              {t("button.button_msg12")}
            </button>
          ) : (
            <button className="button" type="submit" onClick={submit}>
              {t("button.button_msg7")}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default Ods;
