import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect } from 'react-router-dom';


const SubmitHomeButton = ({ isloading = false, canRedirect}) => {
  const {t} = useTranslation();
  const [click, setClick] = useState(false);
  return(
  <button className="button m-1" type="submit" onClick={()=>setClick(true)}>
    {isloading ? t("button.button_msg12") : t("button.button_msg13")}
    {click && canRedirect && <Redirect to="/" />}
  </button>
  );
}
  export default SubmitHomeButton;