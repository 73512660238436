/* eslint-disable no-undef */
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BsBell, BsBellFill } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import useGetUserId from '../../../../hooks/api/useGetUserId';

import { useOutsideClick } from '@chakra-ui/react';
import { apiCall } from '../../../../crud/api.crud';
import useBoolean from '../../../../hooks/useBoolean';
import { isOsmia } from 'utils/contants'; 


const Window = styled.ul`
  max-width: 320px;
  min-width: 280px;
  left: auto;
  right: 0px;

  @media (max-width: 480px) {
    left: 50%;
    transform: translateX(-60%);
    right: auto;
  }
`;

const Title = styled.li`
  color: var(--primary);
  padding: 0.3rem;
  margin: 0;
  font-weight: 700;
  background-color: #79a7bd3d;
`;

const Info = styled.div`
  color: var(--primary);
  font-weight: 500;
`;
const Date = styled.span`
  font-size: 14px;
  background-color: #c7c7fb2f;
`;
const NotificationItem = styled(Link)`
  width: 300px;
`;
const Badge = styled.span`
  position: absolute;
  right: 18px;
  top: -4px;
  width: 15px;
  height: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 8px;
  font-weight: 700;
  border-radius: 50%;
  color: white;
`;
// const notifications = [{message: 'Se agrego la activada tanto',"link":"/detail/244"},{message: 'Se agrego la activada otra',"link":"/detail/120"}]

const Notification = () => {
  const { t } = useTranslation();
  const { isOpen, onToggle, onClose } = useBoolean();
  const [notifications, setNotifications] = useState([]);
  const [cantNotifications, setCantNotifications] = useState(0);
  const userId = useGetUserId();

  const ref = useRef();
  useOutsideClick({
    ref: ref,
    handler: () => onClose(),
  });

  useEffect(() => {
    const fetchData = async () => {
      const response = await apiCall(
        `notifications/entity/${process.env.REACT_APP_ID_ENTITY}/user/${userId}`,
        null,
        'GET',
      );
      setNotifications(response.data.data);
    };

    fetchData();
  }, []);
  const markAsRead = (id, isRead) => {
    if (isRead) return;
    apiCall(`notifications/markasread/${id}`, null, 'GET');
    const auxNotification = [...notifications];
    const index = auxNotification.findIndex((noti) => noti.id == id);
    auxNotification[index].read_at = true;
    setNotifications(auxNotification);
  };
  useEffect(() => {
    if (notifications.length > 0) {
      setCantNotifications(
        notifications.reduce((acc, notification) => {
          if (!notification.read_at) {
            acc++;
          }
          return acc;
        }, 0),
      );
    }
  }, [notifications]);

  return (
    <div className="position-relative"  ref={ref}>
      <button
        type="button"
        id="dropdownMenu1"
        data-toggle="dropdown"
        className="btn dropdown-toggle d-flex align-items-center"
        onClick={onToggle}
        style={{ padding: '5px' }}
      >
        {notifications.length > 0 ? (
          <>
            {cantNotifications > 0 ? (
              <BsBellFill className="mr-1" style={{ fontSize: '18px' }} />
            ) : (
              <BsBell className="mr-1" style={{ fontSize: '18px' }} />
            )}
            {cantNotifications > 0 && <Badge className="bg-primary">{cantNotifications}</Badge>}
            <span className="caret" />
          </>
        ) : (
          <BsBell className="mr-1" style={{ fontSize: '18px' }} />
        )}
      </button>
      <Window className={`dropdown-menu rounded  position-absolute p-2 ${isOpen && 'show'}`}>
        <Title className="text-center">{t('header.header_msg7')}</Title>
        <li className="flex my-2">
          {notifications.length &&
            notifications.map((notification, index) => (
              <NotificationItem
                onClick={() => markAsRead(notification.id, notification.read_at)}
                key={notification.url + index}
                className={`item-menu-notification ${notification.read_at && 'bg-light'}`}
                to={notification.url}
              >
                <Info>{notification.title}</Info>
                {!isOsmia &&
                <Date className="text-muted">{notification.created_at}</Date>}
              </NotificationItem>
            ))}
        </li>
      </Window>
    </div>
  );
};

export default Notification;
