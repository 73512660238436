const list = {
    name: [
      'Fin de la pobreza (Objetivo 1)',
      'Hambre cero (Objetivo 2)',
      'Salud y bienestar (Objetivo 3)',
      'Educación de calidad (Objetivo 4)',
      'Igualdad de género y empoderamiento de la mujer (Objetivo 5)',
      'Agua limpia y saneamiento (Objetivo 6)',
      'Energía asequible y no contaminante (Objetivo 7)',
      'Trabajo decente y crecimiento económico (Objetivo 8)',
      'Industria, innovación e infraestructura (Objetivo 9)',
      'Reducción de las desigualdades entre países y dentro de ellos (Objetivo 10)',
      'Ciudades y comunidades sostenibles (Objetivo 11)',
      'Producción y consumo responsables (Objetivo 12)',
      'Acción por el clima (Objetivo 13)',
      'Vida submarina (Objetivo 14)',
      'Vida de ecosistemas terrestres (Objetivo 15)',
      'Paz, justicia e instituciones sólidas (Objetivo 16)',
      'Alianzas para lograr los objetivos (Objetivo 17)',
    ],
    link:[
        'https://www.un.org/sustainabledevelopment/es/poverty/',
        'https://www.un.org/sustainabledevelopment/es/hunger/',
        'https://www.un.org/sustainabledevelopment/es/health/',
        'https://www.un.org/sustainabledevelopment/es/education/',
        'https://www.un.org/sustainabledevelopment/es/gender-equality/',
        'https://www.un.org/sustainabledevelopment/es/water-and-sanitation/',
        'https://www.un.org/sustainabledevelopment/es/energy/',
        'https://www.un.org/sustainabledevelopment/es/economic-growth/',
        'https://www.un.org/sustainabledevelopment/es/infrastructure/',
        'https://www.un.org/sustainabledevelopment/es/inequality/',
        'https://www.un.org/sustainabledevelopment/es/cities/',
        'https://www.un.org/sustainabledevelopment/es/sustainable-consumption-production/',
        'https://www.un.org/sustainabledevelopment/es/climate-change-2/',
        'https://www.un.org/sustainabledevelopment/es/oceans/',
        'https://www.un.org/sustainabledevelopment/es/biodiversity/',
        'https://www.un.org/sustainabledevelopment/es/peace-justice/',
        'https://www.un.org/sustainabledevelopment/es/globalpartnerships/',
    ]
};

export default list;