import { all } from 'redux-saga/effects';
import { combineReducers } from 'redux';
import * as auth from './ducks/auth.duck';
import * as activities from './ducks/activities.duck';
import * as modal from './ducks/modal.duck';
import * as userActivities from './ducks/user/activities.duck';
import * as userActivitiesHours from './ducks/user/activitiesHours.duck';
import * as security from './ducks/security/index.duck';

export const rootReducer = () => combineReducers({
  auth: auth.reducer,
  activities: activities.reducer,
  modal: modal.reducer,
  userActivities: userActivities.reducer,
  userActivitiesHours: userActivitiesHours.reducer,
  security: security.reducer,
});

export function* rootSaga() {
  yield all([
    auth.saga(),
    activities.saga(),
    userActivities.saga(),
    userActivitiesHours.saga(),
    security.saga(),
  ]);
}
