import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { apiCall } from '../../../../crud/api.crud';
import ManagementFilter from './components/ManagementFilter';
import Table from './components/Table';


const useQuery = () => {
  return new URLSearchParams(useLocation().search);
}

const Management = ({ user }) => {

  const query = useQuery();

  

  const [data, setData] = useState({});
  const [filter, setFilter] = useState({actions: query.get("actions"),status:query.get("status"),status_postulation:query.get("status_postulation")});

  localStorage.setItem("filter", JSON.stringify(filter));


  useEffect(() => {
    apiCall(`management/${user.id}`, null, 'GET').then((response) => setData(response.data.data));
  }, [user.id]);

  return (
    <div className="container mt-4">
      <div className="card shadow  bg-white rounded">
        <div className="card-block">
          <div className="card-body">
            <ManagementFilter filter={filter} setFilter={setFilter}/>
            <Table data={data} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Management;
