
import { useTranslation } from 'react-i18next';
import Select from '../../../../../molecules/Form/components/Select';


const MaritalSelect = () => {
  const {t} = useTranslation();

  const maritalStatus = [
    { label: t("profile.marital_msg"), value: 'Soltero' },
    { label:  t("profile.marital_msg2"), value: 'Casado' },
    { label:  t("profile.marital_msg3"), value: 'Viudo' },
    { label:  t("profile.marital_msg4"), value: 'Divorciado' },
  ];
  
  return(
  <Select
    label={t("profile.profile_msg4")}
    name="marital_status"
    options={maritalStatus}
    validations={[
      { key: 'required', val: true }]}
  />
);}

export default MaritalSelect;
