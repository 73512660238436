import { ChatRouter } from 'modules/chat/routes/ChatRouter';
import { GuideRouter } from 'modules/classroom/routes/GuideRouter';
import ProfilePage from 'modules/talent/pages/Profile';
import { Talents } from 'modules/talent/pages/Talents';
import { useState } from 'react';
import { Route } from 'react-router-dom';
import { showTalent } from 'utils/talents.util';
import config from '../../config';
import { SocketProvider } from '../../context/SocketContext';
import useStore from '../../store/zustand/store';
import ModalRoot from '../molecules/Modal/ModalRoot';
import Navbar from '../organisms/Navbar';
import Activities from '../pages/Private/Activities';
import { DownloadCertificate } from '../pages/Private/Activities/DownloadCertificate';
import Community from '../pages/Private/Comunity';
import CommunityDetail from '../pages/Private/Comunity/components/CommunityDetail';
import DetailActivity from '../pages/Private/DetailActivity';
import CloseActivities from '../pages/Private/Home/components/CloseActivities';
import Home from '../pages/Private/Home/index.jsx';
import Hours from '../pages/Private/Hours';
import Management from '../pages/Private/Management';
import Profile from '../pages/Private/Profile';
import MagicLink from '../pages/Public/MagicLink';
import Conditional from '../shared/Conditional';
import BugAlert from './BugAlert';
import Reminder from './components/Reminder';
import './layout.scss';

// const client = new WebSocket('ws://localhost:8080/ws?name=matias');

const entityID = process.env.REACT_APP_ID_ENTITY;

const Main = () => {
  const user = useStore((state) => state.user);
  const bug = false;
  // isOpenReminder true abre el Reminder y cuando se cierra, abre el el Reactour
  const [isOpenReminder, setIsOpenReminder] = useState(false);
  const onClose = () => setIsOpenReminder(false);
  const onOpen = () => setIsOpenReminder(true);
  // useEffect(() => {
  //   client.onopen = () => {
  //     console.log('WebSocket Client Connected');
  //   };

  //   client.onmessage = (message) => {
  //     console.log(message);
  //   };
  // }, []);

  return (
    <SocketProvider>
      {bug ? (
        <BugAlert />
      ) : (
        <div className="bg-light pb-5 large-padding" style={{ minHeight: '100vh' }}>
          <Navbar user={user} />
          <Conditional condition={user}>
            <Reminder user={user} isOpenReminder={isOpenReminder} onClose={onClose} onOpen={onOpen} />
          </Conditional>
          <Route exact path="/" render={() => <Home isOpenTour={!isOpenReminder} />} />

          <Conditional condition={showTalent(user)}>
            <Route exact path="/talents" component={Talents} />
            <Route exact path="/profile/:id" component={ProfilePage} />
          </Conditional>

          <Route exact path="/detail/:id" component={DetailActivity} />
          <Route path="/detail/:id/guide" component={GuideRouter} />

          <Route exact path="/closed-activities" component={CloseActivities} />
          <Route  path="/authentication" component={MagicLink} />
          <Route exact path="/authentication/:id" component={MagicLink} />

          <Route path="/chat" component={ChatRouter} />

          <Conditional condition={user}>
            <Route exact path="/actividad" render={() => <Activities isOpenTour={!isOpenReminder} />} />
            <Route exact path="/actividad/:id/certificado" component={DownloadCertificate} />

            {config.has_hours && <Route exact path="/horas" render={() => <Hours isOpenTour={!isOpenReminder} />} />}
            <Route exact path="/community" component={Community} />
            <Route exact path="/community/detail/:id" component={CommunityDetail} />

            <Route exact path="/profile">
              <Profile />
            </Route>
            <Route exact path="/management">
              <Management user={user} />
            </Route>
          </Conditional>
          <ModalRoot />
        </div>
      )}
    </SocketProvider>
  );
};

export default Main;
