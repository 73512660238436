import { useState } from 'react';
import { Route, Switch } from 'react-router';
import Modal from '../Modal';
import ForgottenPassword from './components/ForgottenPassword';
import Login from './components/Login';
import Register from './components/Register';
import ResetPassword from './components/ResetPassword';

const LoginModal = ({ isOpen, setIsOpen }) => {
  const [email, setEmail] = useState('');
  const [step, setStep] = useState(1);

  return (
    <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
      {(() => {
        switch (step) {
          case 1:
            return <Login setStep={setStep} setIsOpen={setIsOpen} email={email} setEmail={setEmail} />;
          case 2:
            return <Register setStep={setStep} setIsOpen={setIsOpen} />;
          case 3:
            return <ForgottenPassword setStep={setStep} setIsOpen={setIsOpen} email={email} setEmail={setEmail} />;
        }
      })()}
      <Switch>
        <Route exact path="/password/reset/:id">
          <ResetPassword setStep={setStep} isOpen={isOpen} setIsOpen={setIsOpen} />
        </Route>
      </Switch>
    </Modal>
  );
};
export default LoginModal;
