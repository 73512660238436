import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Auth from './components/main/Auth';
import Layout from './components/main/Layout';
import config from './config';
import useStore from './store/zustand/store';

const Routing = () => (config.withoutLogin ? <WithoutLogin /> : <WithLogin />);

const WithLogin = () => {
  const user = useStore((state) => state.user);
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/password/reset/:id">
          <Auth />
        </Route>
        <Route exact path="/authentication/:id">
          <Auth />
        </Route>
        {!user ? <Auth /> : <Layout />}
      </Switch>
    </BrowserRouter>
  );
};

const WithoutLogin = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Layout />
      </Switch>
    </BrowserRouter>
  );
};

export default Routing;
