import { Button, ButtonGroup, Center, Icon, IconButton, SimpleGrid, Skeleton, Stack } from '@chakra-ui/react';
import { apiCall } from 'crud/api.crud';
import useDebounce from 'hooks/useDebounce';
import { Response, Talent } from 'interfaces';
import Banners from 'modules/talent/components/Banners';
import { Card } from 'modules/talent/components/Card';
import { Filters } from 'modules/talent/components/Filters';
import { useEffect, useState } from 'react';
import { FiChevronLeft, FiChevronRight, FiChevronsLeft, FiChevronsRight } from 'react-icons/fi';

export interface Filter {
  states: string[];
  skills: string[];
  localities: string[];
  name: string;
}

const initialFilter = {
  states: [],
  skills: [],
  localities: [],
  name: '',
};

export function Talents() {
  const [isLoading, setIsLoading] = useState(true);
  const [filter, setFilter] = useState<Filter>(initialFilter);
  const [page, setPage] = useState(1);
  const [data, setData] = useState<Response<Talent>>();
  const filters = useDebounce(filter, 1000);

  useEffect(() => {
    const getData = async () => {
      setIsLoading(true);

      const params = new URLSearchParams();

      if (page) {
        params.append('page[number]', String(page));
      }

      Object.keys(filters).forEach((key) => {
        if (filters[key].length) {
          params.append(`filter[${key}]`, filters[key].toString());
        }
      });

      const { data: result } = await apiCall(
        `entity/${process.env.REACT_APP_ID_ENTITY}/talents?${params.toString()}`,
        null,
        'GET',
      );
      setData(result);
      setIsLoading(false);
    };
    getData();
  }, [filters, page]);

  return (
    <Stack px={8} spacing={12}>
      <Banners />
      <Filters filter={filter} setFilter={setFilter} />
      <SimpleGrid columns={[1, null, 2]} spacing={4}>
        {isLoading && [1, 2, 3, 4, 5, 6].map((i) => <Skeleton key={i} h={200} rounded="xl" />)}
        {!isLoading && data?.data.map((talent) => <Card key={talent.id} talent={talent} />)}
      </SimpleGrid>
      <Center>
        <ButtonGroup size="sm" isDisabled={isLoading}>
          <IconButton
            icon={<FiChevronsLeft />}
            aria-label="First page"
            onClick={() => setPage(1)}
            isDisabled={page === 1}
          />
          <IconButton
            icon={<FiChevronLeft />}
            aria-label="Prev page"
            isDisabled={page === 1}
            onClick={() => setPage(page - 1)}
          />
          <Button variant="outline">{page}</Button>
          <IconButton icon={<FiChevronRight />} aria-label="Next page" onClick={() => setPage(page + 1)} />
          <IconButton icon={<FiChevronsRight />} aria-label="Next page" onClick={() => setPage(page + 2)} />
        </ButtonGroup>
      </Center>
    </Stack>
  );
}
