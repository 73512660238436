import { useTranslation } from "react-i18next";


const SubmitButton = ({ isloading = false }) => {
  const {t} = useTranslation();
  return(
  <button className="button" type="submit">
    {isloading ? t("button.button_msg12") : t("button.button_msg11")}
  </button>
);}

export default SubmitButton;
